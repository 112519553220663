import React, { CSSProperties } from "react";
import classnames from "classnames";
import commonStyles from "../common.module.scss";
import { Breakpoint } from "react-socks";
import { ProgressiveImage } from "../progressive-image/progressive-image";

type SectionProps = {
  title?: string;
  imgFirst?: boolean;
  img?: string;
  imgPlaceholder?: string;
  paragraphs?: string[];
  imgWidth?: string;
  imgHeight?: string;
  imgPortrait?: boolean;
  noAnimate?: boolean;
  style?: CSSProperties
}

type SectionImgProps = {
  width?: string;
  height?: string;
}

export const Section = ({ title, imgFirst, img, imgPlaceholder, paragraphs, imgWidth, imgHeight, imgPortrait, noAnimate, style }: SectionProps) => {

  const SectionImg = (imgProps: SectionImgProps) => (<ProgressiveImage
    width={imgWidth}
    height={imgHeight}
    className={commonStyles.sectionImage}
    src={img}
    imgPlaceholder={imgPlaceholder || img}
    {...imgProps}
  />);

  return (
    <div className={noAnimate ? commonStyles.section : classnames(commonStyles.section, "animate__animated", "animate__fadeIn")} style={style}>
      <Breakpoint desktop up>
        {imgFirst && <SectionImg />}
      </Breakpoint>
      <Breakpoint mobile down>
        <SectionImg width={imgPortrait ? 'unset' : '100%'} height={"unset"} />
      </Breakpoint>
      <div className={commonStyles.sectionTextContainer}>
        {title && <p className={commonStyles.sectionTitle}>{title}</p>}
        {(paragraphs ?? []).map((p, i) => (<p className={p.startsWith("'") ? commonStyles.standOutSectionText : commonStyles.normalSectionText} key={i}>{p}</p>))}
      </div>
      <Breakpoint desktop up>
        {!imgFirst && <SectionImg />}
      </Breakpoint>
    </div>
  )
};