import React, { useState } from "react";
import styles from "./progressive-image.module.scss"
import * as PropTypes from "prop-types";
type ProgressiveImageProps = {
  src?: string,
  imgPlaceholder?: string,
  className?: string,
  alt?: string,
  height?: string,
  width?: string
}

export const ProgressiveImage = ({ src, imgPlaceholder, className, alt, height, width }: ProgressiveImageProps) => {

  const [imgLoaded, setImgLoaded] = useState(false);
  const dimensions = { height: height || "375px", width: width || "500px" }

  return (
    <span style={{ position: "relative" }}>
      <img
        className={`${className}`}
        onLoad={() => setImgLoaded(true)}
        src={src}
        alt={alt}
        style={dimensions}
      />
      <img
        className={`${className} ${styles.overlay}`}
        {...imgLoaded && { style: { opacity: "0" }, ...dimensions }}
        src={imgPlaceholder}
        alt={alt}
      />
    </span>
  );
};

ProgressiveImage.propTypes = {
  src: PropTypes.string,
  imgPlaceholder: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};
