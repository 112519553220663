import React, { useEffect } from "react"
import strings from "../resources/strings.json";
import classnames from 'classnames';
import aboutUs1 from "../resources/images/aboutUs1.jpg"
import aboutUs2 from "../resources/images/aboutUs2.jpg"
import aboutUs1Thumb from "../resources/images/aboutUs1_tn.jpg"
import aboutUs2Thumb from "../resources/images/aboutUs2_tn.jpg"
import { scrollToTop } from "../components/page-helper";
import { Section } from "../components/common/section";
import commonStyles from "../components/common.module.scss";
import { ProgressiveImage } from "../components/progressive-image/progressive-image";

export const AboutUs = () => {

  useEffect(() => {
    scrollToTop();
  });

  return (
    <div>
      <div id={"top-section"} className={classnames("animate__animated", "animate__fadeIn")}>
        <p className={commonStyles.sectionTitle}>{strings.aboutUs.title}</p>
      </div>
      <div className={classnames("animate__animated", "animate__fadeIn")}>{strings.aboutUs.summary}</div>
      <Section img={aboutUs1} imgPlaceholder={aboutUs1Thumb} imgHeight={"333px"} paragraphs={[strings.aboutUs.content1, strings.aboutUs.content2]} />
      <div className={classnames("animate__animated", "animate__fadeIn")}>
        <p>{strings.aboutUs.content3}</p>
        <p>{strings.aboutUs.content4}</p>
        <ProgressiveImage
          width={"100%"}
          height={"unset"}
          className={commonStyles.sectionImageCentered}
          src={aboutUs2}
          imgPlaceholder={aboutUs2Thumb}
        />
      </div>
      {/*<Section img={aboutUs2} imgPlaceholder={aboutUs2Thumb} imgHeight={"333px"} paragraphs={[strings.aboutUs.content3, strings.aboutUs.content4]} imgFirst />*/}
    </div>
  )
}