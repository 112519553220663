import React, { useEffect } from "react"
import strings from "../../resources/strings.json";
import classnames from 'classnames';
import commonStyles from "../common.module.scss";
import jessicasHouseImage1 from "../../resources/images/jessicas_house1.jpg";
import jessicasHouseImage2 from "../../resources/images/jessicas_house3.jpg";
import { scrollToTopWithDelay } from "../page-helper";
import { Section } from "../common/section";
import { DonateNow } from "../common/donate-now-section";

type JessicasHouseProps = {
  openDonatePanel: () => void
}


export const JessicasHouse = ({ openDonatePanel }: JessicasHouseProps) => {

  useEffect(() => {
    scrollToTopWithDelay();
  });

  return (
    <div>
      <div className={classnames("animate__animated", "animate__fadeIn")}>
        <p className={commonStyles.sectionTitle}>{strings.ourProjects.jessicasHouseTitle}</p>
        <br />
        <p>{strings.ourProjects.jessicasHouse1}</p>
      </div>

      <Section img={jessicasHouseImage1} imgPlaceholder={jessicasHouseImage1} paragraphs={[strings.ourProjects.jessicasHouse2]} />
      <Section img={jessicasHouseImage2} imgFirst imgPlaceholder={jessicasHouseImage2} paragraphs={[strings.ourProjects.jessicasHouse3, strings.ourProjects.jessicasHouse4, strings.ourProjects.jessicasHouse5]} />

      <DonateNow openDonatePanel={openDonatePanel} />
    </div>
  )
}
