import React, { useState } from "react"
import { Dialog, Divider, Drawer, Icon } from "@blueprintjs/core";
import styles from "./donate.module.scss";
import commonStyles from "../common.module.scss";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";
import peoplesFundraisingLogo from "../../resources/images/peoples_fundraising.svg"
import strings from "../../resources/strings.json";
import { Breakpoint } from "react-socks";
import { useSmoothNavigation } from "../../useSmoothNavigation";


type DonateDrawerProps = {
  isOpen?: boolean;
  onClose: () => void;
}

export const DonateDrawer = ({ isOpen, onClose }: DonateDrawerProps) => {

  const navigate = useSmoothNavigation()
  const [isDonateDialogOpen, setDonateDialogOpen] = useState(false);

  const confirmDonation = () => {
    window.open("https://www.peoplesfundraising.com/donation/building-together", "_blank", "noopener noreferrer");
    setDonateDialogOpen(false);
  };

  const navigateContactUs = () => {
    onClose();
    navigate("/contact_us");
  }

  const renderDrawerContent = () => (
    <div style={{ height: "100%" }}>
      <div className={styles.donateContainer}>
        <div className={styles.donateClose} onClick={onClose}>
          <Icon icon={IconNames.DOUBLE_CHEVRON_RIGHT} iconSize={20} /></div>
        <div className={styles.donateContent}>
          <div className={commonStyles.sectionTitle}>{strings.donate.title}</div>
          <br />
          <p className={commonStyles.standOutSectionText}>{strings.donate.subTitle}</p>
          <p>{strings.donate.donateInfo1}</p>
          <p>{strings.donate.donateInfo2}</p>
          <p>{strings.donate.donateInfo3}</p>
          <p>{strings.donate.donateInfo4}</p>
          <ul style={{ marginTop: 0 }}>
            <li>{strings.donate.donateInfo4a}</li>
            <li>{strings.donate.donateInfo4b}</li>
            <li>{strings.donate.donateInfo4c}</li>
          </ul>
          <p>{strings.donate.donateInfo5}</p>
          <p>{strings.donate.donateInfo6}</p>
          <Divider className={commonStyles.spacedDivider} />
          <div>
            <div className={commonStyles.sectionSubTitle}>{strings.donate.bankTransfer}</div>
            <br />
            <p>Please use the <span className={commonStyles.textLink} onClick={navigateContactUs}>contact us</span> form to ask for our bank details so you may donate directly</p>
            <Divider className={commonStyles.spacedDivider} />
            <img src={peoplesFundraisingLogo} className={styles.goldenGivingImg} alt={"Peoples Fundraising"} />
            <div className={styles.donatePreferenceContainer}>
              <p>Peoples Fundraising is a charity that provides a web platform to allow other charities to engage with their
                supporters, they facilitate both one-off and regular donations. You can read more about Peoples Fundraising <span className={commonStyles.textLink}
                  onClick={() => window.open("https://www.peoplesfundraising.com/home", "_blank", "noopener noreferrer")}>here</span></p>
              <div className={classnames(commonStyles.primaryButton, styles.donateButton)}
                onClick={() => setDonateDialogOpen(true)}
              >
                {strings.donate.donateNow}
              </div>
            </div>
            <Divider className={commonStyles.spacedDivider} />
            <div className={commonStyles.sectionSubTitle}>{strings.donate.cheque}</div>
            <br />
            <p>{strings.donate.chequeInfo}</p>
            <div style={{ fontSize: "18px" }}>
              <span>GivingWorks</span><br />
              <span>65 Leadenhall Street</span><br />
              <span>London</span><br />
              <span>EC3A 2AD</span><br /><br />
            </div>
          </div>
        </div>
      </div>
      <Dialog
        className={styles.donateDialog}
        isOpen={isDonateDialogOpen}
        icon={IconNames.INFO_SIGN}
        title={"Redirect Notice"}
        onClose={() => setDonateDialogOpen(false)}
      >
        <p
          className={styles.donateDialogText}>{"We use Peoples Fundraising to manage donations, this will open in a new tab where you can give a one-off donation or set up regular payments"}</p>
        <div className={styles.donateDialogButtonRow}>
          <div className={classnames(commonStyles.secondaryButton, styles.donateDialogButton)}
            onClick={() => setDonateDialogOpen(false)}>Cancel
          </div>
          <div className={classnames(commonStyles.primaryButton, styles.donateDialogButton)}
            onClick={confirmDonation}>Take me there
          </div>
        </div>
      </Dialog>
    </div>
  )

  return (
    <div>
      <Breakpoint wide up>
        <Drawer isOpen={isOpen} onClose={onClose} className={styles.donateDrawer} size={"50%"}>
          {renderDrawerContent()}
        </Drawer>
      </Breakpoint>
      <Breakpoint desktop down>
        <Drawer isOpen={isOpen} onClose={onClose} className={styles.donateDrawer} size={"80%"}>
          {renderDrawerContent()}
        </Drawer>
      </Breakpoint>

    </div>
  )
}