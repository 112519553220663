import React from "react"
import { BLOGS } from "./blogs";
import { BlogPost } from "./blog-post";
import { Divider } from "@blueprintjs/core/lib/esm/index";
import styles from "./blog.module.scss"
import strings from "../../resources/strings.json";
import classnames from "classnames";

export const Blog = () => {

  return (
    <div className={classnames("animate__animated", "animate__fadeIn")}>
      <p className={styles.blogIntro}>{strings.blog.intro}</p>
      <Divider />
      {BLOGS.map((blog, index) => <BlogPost key={index} {...blog} />)}
    </div>
  )
}