import React, { useEffect } from "react"
import strings from "../resources/strings.json";
import WhatWeDo1 from "../resources/images/WhatWeDo1.jpg";
import WhatWeDo2 from "../resources/images/WhatWeDo2.jpg";
import WhatWeDo3 from "../resources/images/WhatWeDo3.jpg";
import WhatWeDo1Thumb from "../resources/images/WhatWeDo1_tn.jpg";
import WhatWeDo2Thumb from "../resources/images/WhatWeDo2_tn.jpg";
import WhatWeDo3Thumb from "../resources/images/WhatWeDo3_tn.jpg";
import { scrollToTop } from "../components/page-helper";
import { Section } from "../components/common/section";
import classnames from "classnames";
import commonStyles from "../components/common.module.scss";

export const WhatWeDo = () => {

  useEffect(() => {
    scrollToTop();
  });

  return (
    <div>
      <div id={"top-section"} className={classnames("animate__animated", "animate__fadeIn")}>
        <p className={commonStyles.sectionTitle}>{strings.whatWeDo.title}</p>
      </div>
      <Section img={WhatWeDo1} imgPlaceholder={WhatWeDo1Thumb} title={strings.whatWeDo.missionStatementTitle} paragraphs={[strings.whatWeDo.content1]} />
      <Section img={WhatWeDo2} imgPlaceholder={WhatWeDo2Thumb} title={strings.whatWeDo.ourVisionTitle} paragraphs={[strings.whatWeDo.content2, strings.whatWeDo.content3]} imgFirst />
      <Section img={WhatWeDo3} imgPlaceholder={WhatWeDo3Thumb} title={strings.whatWeDo.realisingTheVisionTitle} paragraphs={[strings.whatWeDo.content4, strings.whatWeDo.content5]} />
    </div>
  )
}