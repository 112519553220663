import React, { useEffect } from "react"
import strings from "../../resources/strings.json";
import classnames from 'classnames';
import commonStyles from "../common.module.scss";
import toiletsAndWellsImage from "../../resources/images/toiletsAndWells.jpg";
import toiletsAndWellsImageThumb from "../../resources/images/toiletsAndWells_tn.jpg";
import { scrollToTopWithDelay } from "../page-helper";
import { Section } from "../common/section";
import { DonateNow } from "../common/donate-now-section";

type ToiletsAndWellsProps = {
  openDonatePanel: () => void
}

export const ToiletsAndWells = ({ openDonatePanel }: ToiletsAndWellsProps) => {

  useEffect(() => {
    scrollToTopWithDelay();
  });

  return (
    <div>
      <div className={classnames("animate__animated", "animate__fadeIn")}>
        <p className={commonStyles.sectionTitle}>{strings.ourProjects.toiletsAndWellsTitle}</p>
        <br />
        <p>{strings.ourProjects.toiletsAndWells1}</p>
      </div>

      <Section img={toiletsAndWellsImage} imgPlaceholder={toiletsAndWellsImageThumb} paragraphs={[strings.ourProjects.toiletsAndWells2, strings.ourProjects.toiletsAndWells3, strings.ourProjects.toiletsAndWells4]} />

      <DonateNow openDonatePanel={openDonatePanel} />
    </div>
  )
}
