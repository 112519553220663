import React, { useState } from "react";
import { Divider, Icon, IconSize } from "@blueprintjs/core";
import styles from "./blog.module.scss";
import commonStyles from "../common.module.scss";
import { IconNames } from "@blueprintjs/icons";
import { ProgressiveImage } from "../progressive-image/progressive-image";

type BlogPostProps = {
  date: string;
  title: string;
  text: string[];
  image: string;
}

export const BlogPost = ({ date, title, text, image }: BlogPostProps) => {

  const [showMore, setShowMore] = useState(false);

  return (
    <div>
      <div className={showMore ? styles.blogPostMore : styles.blogPostLess}>
        <div className={styles.blogHeader}>
          <span>{date}</span>
          <Divider style={{ margin: "0 10px" }} />
          <span>{title}</span>
        </div>
        <div className={styles.blogPostContainer}>
          {text.map((text, index) => <p key={index}>{text}</p>)}
          <ProgressiveImage
            width={"80%"}
            height={"unset"}
            src={image}
            imgPlaceholder={image}
            className={commonStyles.sectionImageCentered}
          />
        </div>

      </div>
      <div className={styles.blogFade} />
      <Icon className={styles.readMoreButton} icon={showMore ? IconNames.DOUBLE_CHEVRON_UP : IconNames.DOUBLE_CHEVRON_DOWN} iconSize={IconSize.LARGE} onClick={() => setShowMore(!showMore)} />
      <Divider />
    </div>
  )
}