import React, { CSSProperties } from 'react';
import styles from "./banner.module.scss";
import classnames from "classnames";

type MenuButtonProps = {
  selected?: boolean;
  small?: boolean;
  style?: CSSProperties;
  text: string;
  onClick: () => void
}

export const MenuButton = ({ selected, style, text, small, onClick }: MenuButtonProps) => {

  const classNames = selected ? [styles.menuButtonSelected] : [styles.menuButtonUnselected];
  if (small) {
    classNames.push(styles.menuButtonSmall);
  }

  return (
    <div className={classnames(classNames)} onClick={onClick} style={style}>{text}</div>
  )
}