import React from 'react';
import { Slide } from 'react-slideshow-image';
import styles from "./slideshow.module.scss"

const importAll = (r: any) => {
  return r.keys().map(r);
}
const slideImages = importAll(require.context('../../resources/images/slideshow', false, /\.(png|jpe?g|svg)$/));

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true
};

export const Slideshow = () => {
  return (
    <div id={"slideshow"} className={styles.slideContainer}>
      <Slide {...properties}>
        <div className={styles.eachSlide}>
          <div style={{ 'backgroundImage': `url(${slideImages[0]})` }}>
          </div>
        </div>
        <div className={styles.eachSlide}>
          <div style={{ 'backgroundImage': `url(${slideImages[1]})` }}>
          </div>
        </div>
        <div className={styles.eachSlide}>
          <div style={{ 'backgroundImage': `url(${slideImages[2]})` }}>
          </div>
        </div>
      </Slide>
    </div>
  )
}