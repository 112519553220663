import React from 'react';
import givingWorksLogo from '../../resources/images/givingworks-logo.png';
import strings from '../../resources/strings.json';
import styles from './footer.module.scss';

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <img src={givingWorksLogo} alt={'Building Together Foundation'} />
      <p>{strings.footer.orgInfo}</p>
    </div>
  );
};
