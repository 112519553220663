import React, { useEffect } from "react"
import strings from "../../resources/strings.json";
import classnames from 'classnames';
import commonStyles from "../common.module.scss";
import medicalCampsImage from "../../resources/images/medicalCamps1.jpg";
import medicalCampsImageThumb from "../../resources/images/medicalCamps1_tn.jpg";
import { scrollToTopWithDelay } from "../page-helper";
import { Section } from "../common/section";
import { DonateNow } from "../common/donate-now-section";

type MedicalCampsProps = {
  openDonatePanel: () => void
}

export const MedicalCamps = ({ openDonatePanel }: MedicalCampsProps) => {

  useEffect(() => {
    scrollToTopWithDelay();
  });

  return (
    <div>
      <div className={classnames("animate__animated", "animate__fadeIn")}>
        <p className={commonStyles.sectionTitle}>{strings.ourProjects.medicalCampsTitle}</p>
        <br />
        <p>{strings.ourProjects.medicalCamps1}</p>
      </div>

      <Section img={medicalCampsImage} imgPlaceholder={medicalCampsImageThumb} paragraphs={[strings.ourProjects.medicalCamps2, strings.ourProjects.medicalCamps3, strings.ourProjects.medicalCamps4]} />

      <div className={classnames("animate__animated", "animate__fadeIn")}>
        <div className={commonStyles.sectionTextContainer}>
          <p>{strings.ourProjects.medicalCamps5}</p>
        </div>
      </div>

      <DonateNow openDonatePanel={openDonatePanel} />

    </div>
  )
}
