import React, { useState } from 'react'
import { Divider, PopoverInteractionKind, PopoverPosition } from "@blueprintjs/core";
import logo from "../../resources/images/btf-logo.svg"
import strings from "../../resources/strings.json";
import styles from "./banner.module.scss";
import { MenuButton } from "./menu-button";
import { Breakpoint } from "react-socks";
import menuIcon from "../../resources/images/menu.png"
import { DonateDrawer } from "../donate/donate";
import { useLocation } from 'react-router';
import { useSmoothNavigation } from '../../useSmoothNavigation';
import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2"

interface BannerProps {
  donateDrawerOpen: boolean;
  setDonateDrawerOpen: (open: boolean) => void,
}

interface MenuProps {
  className: string;
  isMobile?: boolean;
}

export const Banner = ({ donateDrawerOpen, setDonateDrawerOpen }: BannerProps) => {

  const [menuOpen, setMenuOpen] = useState(false)
  const location = useLocation()
  const navigate = useSmoothNavigation()

  function isSelected(path: string) {
    return path !== "/" && location.pathname.includes(path);
  }

  function handleMenuClick(screen: string) {
    setMenuOpen(false);
    navigate(screen);
  }

  const MenuSection = ({ className, isMobile }: MenuProps) => (
    <div className={className}>
      <MenuButton selected={location.pathname === "/"} onClick={() => handleMenuClick("/")} text={strings.banner.home} />
      <Divider />
      <MenuButton selected={isSelected("/about_us")} onClick={() => handleMenuClick("/about_us")} text={strings.banner.aboutUs} />
      <Divider />
      <MenuButton selected={isSelected("/what_we_do")} onClick={() => handleMenuClick("/what_we_do")} text={strings.banner.whatWeDo} />
      <Divider />
      {isMobile ?
        <div>
          <MenuButton selected={isSelected("/projects")} onClick={() => handleMenuClick("/projects")} text={strings.banner.ourProjects} />
          <Divider />
          <MenuButton small selected={isSelected("/projects/beautiful_feet")} onClick={() => handleMenuClick("/projects/beautiful_feet")} text={strings.banner.beautifulFeet} />
          <Divider />
          <MenuButton small selected={isSelected("/projects/jungle_village_school")} onClick={() => handleMenuClick("/projects/jungle_village_school")} text={strings.banner.jungleVillageSchool} />
          <Divider />
          <MenuButton small selected={isSelected("/projects/jessicas_house")} onClick={() => handleMenuClick("/projects/jessicas_house")} text={strings.banner.jessicasHouse} />
          <Divider />
          <MenuButton small selected={isSelected("/projects/childrens_tuition")} onClick={() => handleMenuClick("/projects/childrens_tuition")} text={strings.banner.childrensTuition} />
          <Divider />
          <MenuButton small selected={isSelected("/projects/medical_camps")} onClick={() => handleMenuClick("/projects/medical_camps")} text={strings.banner.medicalCamps} />
          <Divider />
          <MenuButton small selected={isSelected("/projects/toilets_and_wells")} onClick={() => handleMenuClick("/projects/toilets_and_wells")} text={strings.banner.toiletsAndWells} />
        </div>
        : <Popover2
          interactionKind={Popover2InteractionKind.HOVER}
          position={PopoverPosition.BOTTOM}
          usePortal={false}
          content={
            <div className={styles.bannerDropDown}>
              <MenuButton selected={isSelected("/projects/beautiful_feet")} onClick={() => handleMenuClick("/projects/beautiful_feet")} text={strings.banner.beautifulFeet} />
              <Divider />
              <MenuButton selected={isSelected("/projects/jungle_village_school")} onClick={() => handleMenuClick("/projects/jungle_village_school")} text={strings.banner.jungleVillageSchool} />
              <Divider />
              <MenuButton selected={isSelected("/projects/jessicas_house")} onClick={() => handleMenuClick("/projects/jessicas_house")} text={strings.banner.jessicasHouse} />
              <Divider />
              <MenuButton selected={isSelected("/projects/childrens_tuition")} onClick={() => handleMenuClick("/projects/childrens_tuition")} text={strings.banner.childrensTuition} />
              <Divider />
              <MenuButton selected={isSelected("/projects/medical_camps")} onClick={() => handleMenuClick("/projects/medical_camps")} text={strings.banner.medicalCamps} />
              <Divider />
              <MenuButton selected={isSelected("/projects/toilets_and_wells")} onClick={() => handleMenuClick("/projects/toilets_and_wells")} text={strings.banner.toiletsAndWells} />
            </div>
          }
        >
          <MenuButton selected={isSelected("/projects")} onClick={() => handleMenuClick("/projects")} text={strings.banner.ourProjects} style={{ height: "100%" }} />
        </Popover2>
      }

      <Divider />
      <MenuButton selected={isSelected("/blog")} onClick={() => handleMenuClick("/blog")} text={strings.banner.blog} />
      <Divider />
      <MenuButton selected={isSelected("/gallery")} onClick={() => handleMenuClick("/gallery")} text={strings.banner.gallery} />
      <Divider />
      <MenuButton selected={isSelected("/contact_us")} onClick={() => handleMenuClick("/contact_us")} text={strings.banner.contactUs} />
    </div>
  );



  return (
    <div id={"banner"} className={styles.banner}>
      <Breakpoint desktop up className={styles.bannerWrapper}>
        <img className={styles.logo} src={logo} alt={"Building Together Foundation"} />
        <MenuSection className={styles.menuButtons} />
        <div />
      </Breakpoint>
      <Breakpoint mobile down className={styles.bannerWrapperMobile}>
        <img className={styles.logo} src={logo} alt={"Building Together Foundation"} />
        <Popover2
          isOpen={menuOpen}
          onClose={() => setMenuOpen(false)}
          position={PopoverPosition.BOTTOM_LEFT}
          usePortal={false}
          content={
            <MenuSection className={styles.mobileMenu} isMobile />
          }
        >
          <div className={styles.mainMenuButton} onClick={() => setMenuOpen(true)}>
            <img src={menuIcon} width={"64px"} alt={""} />
            <div className={styles.menuButtonShadow} />
          </div>
        </Popover2>
      </Breakpoint>
      <div id={"donate"} className={styles.donateButton} onClick={() => setDonateDrawerOpen(true)}>
        {strings.banner.donate}
      </div>
      <DonateDrawer
        isOpen={donateDrawerOpen}
        onClose={() => setDonateDrawerOpen(false)}
      />
    </div>
  )
}