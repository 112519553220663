import React from "react";
import styles from "./home.module.scss";

type ProjectTileProps = {
  image?: string;
  large?: boolean;
  title?: string
  onClick?: () => void
}

export const ProjectTile = ({ image, large, title, onClick }: ProjectTileProps) => {

  const backgroundStyle = { backgroundImage: `url(${image})` };

  return (
    <div style={backgroundStyle} className={large ? styles.projectTileLarge : styles.projectTile} onClick={onClick}>
      <div className={styles.projectTileHeader}><span>{title}</span></div>
    </div>
  )
};