import React from "react";
import styles from "./home.module.scss";
import strings from "../../resources/strings.json";

import beautifulFeetImage from "../../resources/images/beautifulFeet1.jpg";
import jungleVillageSchoolImage from "../../resources/images/jungleVillageSchool1.jpg";
import medicalCampImage from "../../resources/images/medicalCamps1.jpg";
import childrensTuitionImage from "../../resources/images/childrensTuition1.jpg";
import toiletsAndWellsImage from "../../resources/images/toiletsAndWells.jpg";
import jessicasHouseImage from "../../resources/images/jessicas_house1.jpg"
import {ProjectTile} from "./project-tile";
import {Breakpoint} from "react-socks";
import { useSmoothNavigation } from "../../useSmoothNavigation";

export const ProjectsGrid = () => {

  const navigate = useSmoothNavigation()

  return (
    <div className={styles.ourProjects}>
      <Breakpoint desktop up className={styles.projectsGridContainer}>
        <div id={"our-projects"} className={styles.ourProjectsTitle}>{strings.ourProjects.title}</div>
        <div className={styles.projectsGridRow}>
          <ProjectTile title={strings.ourProjects.beautifulFeetTitle} image={beautifulFeetImage} onClick={() => navigate("/projects/beautiful_feet")}/>
          <ProjectTile title={strings.ourProjects.jessicasHouseTitle} image={jessicasHouseImage} onClick={() => navigate("/projects/jessicas_house")}/>
          <ProjectTile title={strings.ourProjects.jungleSchoolTitle} image={jungleVillageSchoolImage} onClick={() => navigate("/projects/jungle_village_school")}/>
        </div>
        <div className={styles.projectsGridRow}>
          <ProjectTile title={strings.ourProjects.medicalCampsTitle} image={medicalCampImage} onClick={() => navigate("/projects/medical_camps")}/>
          <ProjectTile title={strings.ourProjects.freeTuitionTitle} image={childrensTuitionImage} onClick={() => navigate("/projects/childrens_tuition")}/>
          <ProjectTile title={strings.ourProjects.toiletsAndWellsTitle} image={toiletsAndWellsImage} onClick={() => navigate("/projects/toilets_and_wells")}/>
        </div>
      </Breakpoint>
      <Breakpoint mobile down className={styles.projectsGridContainer}>
        <div id={"our-projects"} className={styles.ourProjectsTitle}>{strings.ourProjects.title}</div>
        <ProjectTile title={strings.ourProjects.beautifulFeetTitle} image={beautifulFeetImage} onClick={() => navigate("/projects/beautiful_feet")}/>
        <ProjectTile title={strings.ourProjects.jessicasHouseTitle} image={jessicasHouseImage} onClick={() => navigate("/projects/jessicas_house")}/>
        <ProjectTile title={strings.ourProjects.jungleSchoolTitle} image={jungleVillageSchoolImage} onClick={() => navigate("/projects/jungle_village_school")}/>
        <ProjectTile title={strings.ourProjects.medicalCampsTitle} image={medicalCampImage} onClick={() => navigate("/projects/medical_camps")}/>
        <ProjectTile title={strings.ourProjects.freeTuitionTitle} image={childrensTuitionImage} onClick={() => navigate("/projects/childrens_tuition")}/>
        <ProjectTile title={strings.ourProjects.toiletsAndWellsTitle} image={toiletsAndWellsImage} onClick={() => navigate("/projects/toilets_and_wells")}/>
      </Breakpoint>
    </div>
  )
}