import { AppToaster } from "../components/toaster";
import { Settings } from "../config/settings";

export function sendContactEmail(formData: Record<string, string | null | undefined>) {

  return new Promise<void>((resolve) => {
    fetch(`${Settings.SERVER_URL}/api/contact`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(res => {
      if (!res.ok) {
        throw (new Error(res.statusText))
      }
      console.debug(res);
      AppToaster.show({ message: "Thank you for your message" })
      resolve();
    }).catch(err => {
      console.debug(err);
      AppToaster.show({ message: "Message failed to send, please try again" })
      resolve();
    });
  })

}