import React, { useEffect } from "react"
import strings from "../../resources/strings.json";
import classnames from 'classnames';
import commonStyles from "../common.module.scss";
import freeTuitionImage from "../../resources/images/childrensTuition1.jpg";
import freeTuitionImageThumb from "../../resources/images/childrensTuition1_tn.jpg";
import { scrollToTopWithDelay } from "../page-helper";
import { Section } from "../common/section";
import { DonateNow } from "../common/donate-now-section";

type ChildrensTuitionProps = {
  openDonatePanel: () => void
}

export const ChildrensTuition = ({ openDonatePanel }: ChildrensTuitionProps) => {

  useEffect(() => {
    scrollToTopWithDelay();
  });

  return (
    <div>
      <div className={classnames("animate__animated", "animate__fadeIn")}>
        <p className={commonStyles.sectionTitle}>{strings.ourProjects.freeTuitionTitle}</p>
        <br />
        <p>{strings.ourProjects.freeTuition1}</p>
      </div>

      <Section img={freeTuitionImage} imgPlaceholder={freeTuitionImageThumb} paragraphs={[strings.ourProjects.freeTuition2, strings.ourProjects.freeTuition3, strings.ourProjects.freeTuition4, strings.ourProjects.freeTuition5]} />

      <DonateNow openDonatePanel={openDonatePanel} />
    </div>
  )
}
