import { useNavigate } from "react-router"

export const useSmoothNavigation = () => {
    const navigateFn = useNavigate()

    const shouldTransition = (path: string) => {
        return (
            !(window.location.pathname === "/projects" && path === "/projects/beautiful_feet") &&
            !(window.location.pathname === "/projects/beautiful_feet" && path === "/projects") &&
            !(window.location.pathname === "/" && path === "/contact_us") &&
            !(window.location.pathname === "/contact_us" && path === "/")
        )
    }

    const navigateSmoothly = (path: string) => {
        let body = document.getElementById("app-body");
        body?.classList.add("animate__animated", "animate__fadeOut", "animate__faster")
        setTimeout(() => {
            body?.classList.remove("animate__animated", "animate__fadeOut", "animate__faster")
            navigateFn(path);
        }, 500)
    };

    const navigate = (path: string) => {
        if (window.location.pathname !== path) {
            if (shouldTransition(path)) {
                navigateSmoothly(path)
            } else {
                navigateFn(path);
            }
        }
    }


    return navigate;
}