export function scrollToTop () {
  window.scrollTo({top: 0, left: 0});
}

export function scrollToTopWithDelay () {
  setTimeout(() => {
    scrollToTop();
  }, 500)
}

export function scrollElementIntoViewById(id: string) {
  let element = document.getElementById(id);
  element && element.scrollIntoView({behavior: "smooth"});
}

export function scrollElementIntoViewByIdWithDelay(id: string) {
  setTimeout(() => {
    scrollElementIntoViewById(id);
  }, 500)
}