import React from "react";
import classnames from "classnames";
import commonStyles from "../common.module.scss";
import strings from "../../resources/strings.json";
import { ReactNode } from "react";

type DonateNowProps = {
  openDonatePanel: () => void
  children?: ReactNode;
}

export const DonateNow = ({ openDonatePanel, children }: DonateNowProps) => {
  return (
    <div className={classnames("animate__animated", "animate__fadeIn")}>
      <div className={commonStyles.sectionTextContainer}>
        <p className={commonStyles.donateText}><span className={commonStyles.textLink} onClick={openDonatePanel}>{strings.common.donateNow1}</span>{strings.common.donateNow2}</p>
        {children}
      </div>
    </div>
  )
}