import React, { useState } from 'react';
import 'animate.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import 'react-slideshow-image/dist/styles.css'
import './App.css';

import { Home } from "./screens/home";
import { Banner } from "./components/banner/banner";
import { AboutUs } from "./screens/about-us";
import { WhatWeDo } from "./screens/what-we-do";
import { Blog } from "./components/blog/blog";
import { Gallery } from "./components/gallery/gallery";
import { Projects } from "./components/projects/projects";
import { Footer } from "./components/footer/footer";
import { JungleVillageSchool } from "./components/projects/jungle-village-school";
import { MedicalCamps } from "./components/projects/medical-camps";
import { ChildrensTuition } from "./components/projects/childrens-tuition";
import { ToiletsAndWells } from "./components/projects/toilets-and-wells";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { JessicasHouse } from './components/projects/jessicas-house';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {

  const [donateDrawerOpen, setDonateDrawerOpen] = useState(false);

  setDefaultBreakpoints([
    { zero: 0 },
    { mobile: 1124 },
    { desktop: 1125 },
    { wide: 1920 }
  ]);

  const openDonatePanel = () => {
    setDonateDrawerOpen(true);
  };

  return (
    <BrowserRouter>
      <BreakpointProvider>
        <div className="App">
          <Banner donateDrawerOpen={donateDrawerOpen} setDonateDrawerOpen={setDonateDrawerOpen} />
          <div id={"app-body"} className={"App-body"}>
            <div id={"app-content"} className="App-content">
              <Routes>
                <Route path="/about_us" element={<AboutUs />} />
                <Route path="/what_we_do" element={<WhatWeDo />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/projects" element={<Projects openDonatePanel={openDonatePanel} />} />
                <Route path="/projects/beautiful_feet" element={<Projects openDonatePanel={openDonatePanel} />} />
                <Route path="/projects/jungle_village_school" element={<JungleVillageSchool openDonatePanel={openDonatePanel} />} />
                <Route path="/projects/medical_camps" element={<MedicalCamps openDonatePanel={openDonatePanel} />} />
                <Route path="/projects/childrens_tuition" element={<ChildrensTuition openDonatePanel={openDonatePanel} />} />
                <Route path="/projects/toilets_and_wells" element={<ToiletsAndWells openDonatePanel={openDonatePanel} />} />
                <Route path="/projects/jessicas_house" element={<JessicasHouse openDonatePanel={openDonatePanel} />} />
                <Route path="/" element={<Home />} />
                <Route path="/contact_us" element={<Home />} />
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      </BreakpointProvider>
    </BrowserRouter >
  );
}

export default App;
