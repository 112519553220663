import React, { useEffect } from "react";
import classnames from "classnames";
import strings from "../../resources/strings.json";
import commonStyles from "../common.module.scss";
import beautifulFeet1 from "../../resources/images/beautifulFeet1.jpg";
import beautifulFeet2 from "../../resources/images/beautifulFeet2.jpg";
import beautifulFeet3 from "../../resources/images/beautifulFeet3.jpg";
import beautifulFeet5 from "../../resources/images/beautifulFeet5.jpg";
import beautyForAshes from "../../resources/images/beautyForAshes.jpg";
import beautifulFeet1Thumb from "../../resources/images/beautifulFeet1_tn.jpg";
import beautifulFeet2Thumb from "../../resources/images/beautifulFeet2_tn.jpg";
import beautifulFeet3Thumb from "../../resources/images/beautifulFeet3_tn.jpg";
import beautifulFeet5Thumb from "../../resources/images/beautifulFeet5_tn.jpg";
import beautyForAshesThumb from "../../resources/images/beautyForAshes_tn.jpg";
import { Divider } from "@blueprintjs/core/lib/esm/index";
import styles from "../home/home.module.scss";
import { ProjectTile } from "../home/project-tile";
import jungleVillageSchoolImage from "../../resources/images/jungleVillageSchool1.jpg";
import medicalCampImage from "../../resources/images/medicalCamps1.jpg";
import childrensTuitionImage from "../../resources/images/childrensTuition1.jpg";
import toiletsAndWellsImage from "../../resources/images/toiletsAndWells.jpg";
import { scrollElementIntoViewById, scrollToTopWithDelay } from "../page-helper";
import { Section } from "../common/section";
import { DonateNow } from "../common/donate-now-section";
import { Breakpoint } from "react-socks";
import { ProgressiveImage } from "../progressive-image/progressive-image";
import jessicasHouseImage from '../../resources/images/jessicas_house1.jpg';
import { useSmoothNavigation } from "../../useSmoothNavigation";

type ProjectsProps = {
  openDonatePanel: () => void
}

export const Projects = ({ openDonatePanel }: ProjectsProps) => {

  const navigate = useSmoothNavigation()

  useEffect(() => {
    if (window.location.pathname === "/projects/beautiful_feet") {
      scrollElementIntoViewById("top-section");
    } else if (window.location.pathname === "/projects") {
      scrollToTopWithDelay();
    }
  });

  return (
    <div>
      <div id={"top-section"} className={classnames("animate__animated", "animate__fadeIn")}>
        <p className={commonStyles.sectionTitle}>{strings.ourProjects.title}</p>
        {strings.ourProjects.summary}
      </div>
      <div id={"top-section"} className={classnames("animate__animated", "animate__fadeIn")}>
        <Divider className={commonStyles.spacedDivider} />
        <p className={commonStyles.sectionTitle}>{strings.ourProjects.beautifulFeetTitle}</p>
      </div>
      <Section img={beautifulFeet1} imgPlaceholder={beautifulFeet1Thumb} paragraphs={[strings.ourProjects.beautifulFeet1, strings.ourProjects.beautifulFeet2]} />
      <Section img={beautifulFeet2} imgPlaceholder={beautifulFeet2Thumb} paragraphs={[strings.ourProjects.beautifulFeet3, strings.ourProjects.beautifulFeet4]} imgFirst />
      <Section img={beautifulFeet3} imgPlaceholder={beautifulFeet3Thumb} paragraphs={[strings.ourProjects.beautifulFeet5, strings.ourProjects.beautifulFeet6, strings.ourProjects.beautifulFeet7]} />

      <DonateNow openDonatePanel={openDonatePanel} />
      <ProgressiveImage
        width={"100%"}
        height={"unset"}
        src={beautifulFeet5}
        imgPlaceholder={beautifulFeet5Thumb}
        className={commonStyles.sectionImageCentered}
      />
      <div className={classnames("animate__animated", "animate__fadeIn")}>
        <Divider className={commonStyles.spacedDivider} />
        <p className={commonStyles.sectionTitle}>{strings.ourProjects.beautyForAshesTitle}</p>
      </div>
      <Section img={beautyForAshes} imgPlaceholder={beautyForAshesThumb} paragraphs={[strings.ourProjects.beautyForAshes1, strings.ourProjects.beautyForAshes2]} />
      <p className={commonStyles.normalSectionText}>Select one of the projects below to read more about it</p>

      <Breakpoint desktop up className={styles.projectsGridContainer}>
        <div className={styles.projectsGridRowSingle}>
          <ProjectTile large title={strings.ourProjects.jessicasHouseTitle} image={jessicasHouseImage} onClick={() => navigate("/projects/jessicas_house")} />
        </div>
        <div className={styles.projectsGridRowLarge}>
          <ProjectTile large title={strings.ourProjects.jungleSchoolTitle} image={jungleVillageSchoolImage} onClick={() => navigate("/projects/jungle_village_school")} />
          <ProjectTile large title={strings.ourProjects.medicalCampsTitle} image={medicalCampImage} onClick={() => navigate("/projects/medical_camps")} />
        </div>
        <div className={styles.projectsGridRowLarge}>
          <ProjectTile large title={strings.ourProjects.freeTuitionTitle} image={childrensTuitionImage} onClick={() => navigate("/projects/childrens_tuition")} />
          <ProjectTile large title={strings.ourProjects.toiletsAndWellsTitle} image={toiletsAndWellsImage} onClick={() => navigate("/projects/toilets_and_wells")} />
        </div>

      </Breakpoint>
      <Breakpoint mobile down >
        <ProjectTile title={strings.ourProjects.jessicasHouseTitle} image={jessicasHouseImage} onClick={() => navigate("/projects/jessicas_house")} />
        <ProjectTile title={strings.ourProjects.jungleSchoolTitle} image={jungleVillageSchoolImage} onClick={() => navigate("/projects/jungle_village_school")} />
        <ProjectTile title={strings.ourProjects.medicalCampsTitle} image={medicalCampImage} onClick={() => navigate("/projects/medical_camps")} />
        <ProjectTile title={strings.ourProjects.freeTuitionTitle} image={childrensTuitionImage} onClick={() => navigate("/projects/childrens_tuition")} />
        <ProjectTile title={strings.ourProjects.toiletsAndWellsTitle} image={toiletsAndWellsImage} onClick={() => navigate("/projects/toilets_and_wells")} />
      </Breakpoint>
    </div>
  )
}
