import {Position, Toaster, ToastProps} from "@blueprintjs/core";
import styles from './common.module.scss'

/** Singleton toaster instance. Create separate instances for different options. */
const toaster = Toaster.create({
  className: styles.toast,
  position: Position.TOP,
});

export const AppToaster = {

  show(props: ToastProps, key?: string) {
    toaster.show(props, key || "theToast");
  },

  clear() {
    toaster.clear();
  }
};