export const BLOGS = [
  {
    "date": "23rd September 2020",
    "title": "Jungle village school - Joy Academy",
    "text": [
      "During our visit in March we were so relieved to be able to attend the opening of Joy Academy and cut the ribbon before the three month lockdown began and international flights where stopped. Our contractors were still painting at the time but we are pleased to hear that the first four classrooms have been completed.",
      "The children were very sad to be told the school couldn’t open yet but our team have restarted tuition which will run until the government allows schools to open.",
      "The next stage of the project will be to build a kitchen and dining hall so the children can have at least one healthy meal a day, if you would like to help please send us a message.",
      "In order to feed the children we will need your help, you can support this project for as little as £5/month."
    ],
    "image": require('../../resources/images/joyAcademy1.jpg')
  },
  {
    "date": "6th September 2020",
    "title": "Jessica's House",
    "text": [
      "Building Together Foundation is very happy to announce the opening of Jessica's House, a girls home in Siliguri, West Bengal.",
      "Our India team have been aware of many young girls who were living in very difficult circumstances. They all come from West Bengal with very poor families backgrounds. Some have been abandoned by a remaining parent who remarried (sadly very common) or are complete orphans, all have little or no prospect of receiving education. Girls are particularly vulnerable to being trafficked, in West Bengal region nearly 10,000 children go missing every year. ",
      "Jessica’s House will provide a safe and loving environment for them to grow and receive their education at local schools. ",
      "If you would like to partner with us please send a message for further details. ",
      "Each girl will cost around £25/month but you can support this project for any amount over £5/month.",
    ],
    "image": require('../../resources/images/jessicas_house1.jpg')
  },
  {
    "date": "3rd June 2020",
    "title": "Feed India - Covid relief food distribution",
    "text": [
      "As the Indian government imposed lockdown across the country, daily wage earners were left with no means of buying food. 50 million of these are migrant workers who found themselves abandoned in cities, miles away from home and unable to pay rent or buy food.",
      "The Building Together Foundation team began a new project - FEED INDIA.",
      "Working alongside other NGOs and Pastors, they began to identify those most in need; this included a Beggars camp, 2 leprosy colonies, local disabled people, Tea garden families and the remote jungle village where BTF have just completed building Joy Academy. The latter was completely cut off and BTF were the only group given permission to enter to distribute food. One leprosy colony was in a very poor state having received no support for over two weeks; the people were desperate. BTF also fed a large number of migrant workers camping out at the major railway stations giving them a cooked meal.",
      "Families were given food parcels comprising rice, lentils, soy chunks and oil costing around £3/ parcel.",
      "The total amount of money sent by BTF for this project was £7,262.",
      "Thanks to all the donations received during this time you have helped provide food parcels to over 1100 families, some several times and meals for 300 migrant workers, altogether we have fed nearly 5000 individuals!",
      "There is still time to support this project as all costs have not yet been covered."
    ],
    "image": require('../../resources/images/foodDistribution2.jpg')
  },
  {
    "date": "8th December 2019",
    "title": "Dooars Jungle Village School - Update",
    "text": [
      "It was very exciting as we entered the school compound to inspect the work. The boundary wall has been completed and looks completely elephant proof! The classrooms were being measured out and we had the privilege of cutting the first turf ready for the foundations, rather glad we didn’t have to finish the holes as It was 30C.",
      "The plan is to build the classrooms over the next three months ready for school term starting end of February. We still need to raise approximately £9000 to reach our target. It has been truly amazing how the funds have come in so far and we are standing in faith with our India team for the finances to become available in time."
    ],
    "image": require('../../resources/images/jungleVillageSchool7.jpg')
  },
  {
    "date": "16th September 2019",
    "title": "Dooars Jungle Village School",
    "text": [
      "Work started on the boundary wall on the 4th September and is progressing well using local labourers. As a result, more children have started attending the free tuition classes funded by BuildingTogether as news is getting out that the school is under construction.",
      "We are very excited that over 70 children are regularly attending and we have had to bring in second teacher."
    ],
    "image": require('../../resources/images/jungleVillageSchool6.jpg')
  },
  {
    "date": "27th August 2019",
    "title": "Dooars jungle village children’s tuition",
    "text": [
      "Our children’s tuition project started in July with twenty children form the Dooars jungle village receiving education for the first time.",
      "The tuition started during the rainy season so a local cow shed was cleared up so that the  children didn’t get soaked, our teacher was asked by one of them  when the school would be built as they didn’t like the smell.",
      "Thankfully they soon discovered a disused government building that had been a nursery, the  children are receiving 2 hours tuition every week day, smell free, there are now over 70 children attending"
    ],
    "image": require('../../resources/images/childrensTuition1.jpg')
  },
  {
    "date": "26th August 2019",
    "title": "Dooars Jungle village medical camp",
    "text": [
      "In June BuildingTogether held their first medical camp in the Dooars jungle village.  Doctors and medical staff from Siliguri offered their services for free and BuildingTogether provided transport, food and medicines for the day.",
      "The medicines would normally cost around £100 but our friends were able to negotiate an amazing deal with a local pharmacist who sold them at cost for £35! The medical team couldn’t believe it.",
      "150 people were seen and all the medicine handed out, a great blessing for the people who have no access to medical care locally."
    ],
    "image": require('../../resources/images/medicalCamps1.jpg')
  }
]
