
export const PHOTOS = [
  {
    source : require("../../resources/images/ourTeam.jpg"),
    caption: "Our Team"
  },
  {
    source : require("../../resources/images/joyAcademyJungleVillageFoodDistribution1.jpg"),
    caption: "Joy academy jungle village food distribution"
  },
  {
    source : require("../../resources/images/jungleVillageFoodDistribution1.jpg"),
    caption: "Local disabled food distribution"
  },
  {
    source : require("../../resources/images/jungleVillageFoodDistribution2.jpg"),
    caption: "Local disabled food distribution"
  },
  {
    source : require("../../resources/images/localDisabledFoodDistribution.jpg"),
    caption: "Local disabled food distribution"
  },
  {
    source : require("../../resources/images/foodDistribution1.jpg"),
    caption: "Food distribution"
  },
  {
    source : require("../../resources/images/foodDistribution2.jpg"),
    caption: "Food distribution"
  },
  {
    source : require("../../resources/images/foodBags.jpg"),
    caption: ""
  },
  {
    source : require("../../resources/images/workForce.jpg"),
    caption: "Some of our work force"
  },
  {
    source : require("../../resources/images/beggarsCampFoodDistribution1.jpg"),
    caption: "Beggars camp food distribution"
  },
  {
    source : require("../../resources/images/beggarsCampFoodDistribution2.jpg"),
    caption: "Beggars camp food distribution"
  },
  {
    source : require("../../resources/images/beggarsCampBaby.jpg"),
    caption: "Beggars camp baby"
  },
  {
    source : require("../../resources/images/beggarsCampFamily.jpg"),
    caption: "Beggars camp family"
  },
  {
    source : require("../../resources/images/leperColonyFoodDistribution1.jpg"),
    caption: "Leper colony food distribution"
  },
  {
    source : require("../../resources/images/leperColonyFoodDistribution2.jpg"),
    caption: "Leper colony food distribution"
  },
  {
    source : require("../../resources/images/leperColonyFoodDistribution3.jpg"),
    caption: "Leper colony food distribution"
  },
  {
    source : require("../../resources/images/jungleVillageChildren.jpg"),
    caption: "Jungle Village Children"
  },
  {
    source : require("../../resources/images/aboutUs1.jpg"),
    caption: ""
  },
  {
    source : require("../../resources/images/childrensTuition1.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/aboutUs2.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/beautifulFeet2.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/beautifulFeet3.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/beautifulFeet5.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/beautyForAshes.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/children.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/jungleVillageSchool1.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/jungleVillageSchool2.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/jungleVillageSchool3.jpg"),
    caption : ""
  },
  // TODO: This doesn't render correctly
  // {
  //   source : require("../../resources/images/jungleVillageSchool4.jpg"),
  //   caption : ""
  // },
  {
    source : require("../../resources/images/jungleVillageSchool5.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/jungleVillageSchool6.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/jungleVillageSchool7.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/jungleVillageSchool8.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/jungleVillageSchool9.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/medicalCamps1.jpg"),
    caption : ""
  },
  // {
  //   source : require("../../resources/images/toiletsAndWells.jpg"),
  //   caption : ""
  // },
  {
    source : require("../../resources/images/WhatWeDo1.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/WhatWeDo2.jpg"),
    caption : ""
  },
  {
    source : require("../../resources/images/WhatWeDo3.jpg"),
    caption : ""
  }
]