import React, { useEffect, useState } from "react"
import { scrollToTopWithDelay } from "../page-helper";
import { PHOTOS } from "./photos";
import Carousel, { Modal, ModalGateway } from "react-images";
import styles from "./gallery.module.scss";
import classnames from "classnames";
import commonStyles from "../common.module.scss";

export const Gallery = () => {

  const [lightboxIsOpen, setLightboxOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0)

  const toggleLightBox = (index: number) => {
    setSelectedIndex(index);
    setLightboxOpen(!lightboxIsOpen);
  };

  useEffect(() => {
    scrollToTopWithDelay();
  }, []);

  return (
    <div>
      <div id={"top-section"} className={classnames("animate__animated", "animate__fadeIn")}>
        <p className={commonStyles.sectionTitle}>{"Gallery"}</p>
      </div>
      <div className={styles.galleryContainer} >
        {PHOTOS.map(({ source }, j) => (
          <div className={styles.galleryImgContainer} onClick={() => toggleLightBox(j)} key={j}>
            <img alt={""} src={source} className={styles.galleryImg} />
          </div>
        ))}
      </div>
      {/* @ts-ignore */}
      <ModalGateway>
        {lightboxIsOpen ? (
          <Modal onClose={() => toggleLightBox(0)}>
            <Carousel
              currentIndex={selectedIndex}
              views={PHOTOS}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>

  )
}