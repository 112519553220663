import React, { useEffect } from 'react';
import { Slideshow } from "../components/home/slideshow";
import styles from "../components/home/home.module.scss"
import strings from "../resources/strings.json"
import { BoxSection } from "../components/home/box-section";
import { ProjectsGrid } from "../components/home/projects-grid";
import classnames from "classnames";
import { ContactUs } from "../components/contact/contact-us";
import { useSmoothNavigation } from '../useSmoothNavigation';

export const Home = () => {

  const navigate = useSmoothNavigation()


  useEffect(() => {
    if (window.location.pathname === "/contact_us") {
      setTimeout(() => {
        let element = document.getElementById("contact-us-title");
        element && element.scrollIntoView({ behavior: "smooth" });
      }, 500)
    } else if (window.location.pathname === "/") {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, 500)
    }
  });

  return (
    <div id={"home-container"} className={classnames("animate__animated", "animate__fadeIn", "animate__fast")}>
      <Slideshow />
      <div className={styles.sections} >
        <BoxSection className={styles.aboutUsSection} title={strings.aboutUs.title} text={strings.aboutUs.summary} onClick={() => navigate("/about_us")} />
        <BoxSection className={styles.ourProjectsSection} title={strings.ourProjects.title} text={strings.ourProjects.summary} onClick={() => navigate("/projects")} />
        <BoxSection className={styles.latestNewsSection} title={strings.sections.latestNewsTitle} text={strings.sections.latestNewsSummary} onClick={() => navigate("/blog")} />
      </div>
      <ProjectsGrid />
      <ContactUs />
    </div>
  )
}