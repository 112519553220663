import React from "react";
import styles from "./home.module.scss";
import classnames from "classnames";
import { Button, Text } from "@blueprintjs/core";
import strings from "../../resources/strings.json"

type BoxSectionProps = {
  className?: string
  title?: string
  text?: string
  onClick?: () => void
}

export const BoxSection = ({ className, text, title, onClick }: BoxSectionProps) => {

  return (
    <div className={classnames(className)}>
      <Text className={styles.sectionTitle}>{title}</Text>
      <Text className={styles.sectionSummary}>{text}</Text>
      <Button minimal className={styles.readMoreButton} onClick={onClick} text={strings.sections.readMore} />
    </div>
  )
};