import { InputGroup, Intent, Spinner, TextArea } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';
import classnames from 'classnames';
import * as EmailValidator from 'email-validator';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendContactEmail } from '../../api/btf-api';
import { Settings } from '../../config/settings';
import strings from '../../resources/strings.json';
import commonStyles from '../common.module.scss';
import styles from './contact.module.scss';

export const ContactUs = () => {
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const [name, setName] = useState<string | undefined>(undefined);
  const [nameHelperOpen, setNameHelperOpen] = useState(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [emailHelperOpen, setEmailHelperOpen] = useState(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [isSending, setSending] = useState(false);

  function getIntent(value?: string, validateEmail = false) {
    if (value === undefined) return Intent.NONE;
    if (value === '' || (validateEmail && !EmailValidator.validate(value)))
      return Intent.DANGER;
    return Intent.SUCCESS;
  }

  const submitContact = () => {
    setSending(true);
    sendContactEmail({
      name,
      email,
      message,
      captcha,
    }).then(() => {
      setSending(false);
      recaptchaRef.current && recaptchaRef.current.reset();
    });
  };

  const canSubmit =
    name &&
    email &&
    EmailValidator.validate(email) &&
    message &&
    (captcha || Settings.RECAPTCHA_ENABLED === 'false');
  const submitBtnClasses = [
    canSubmit ? commonStyles.primaryButton : commonStyles.disabledButton,
    styles.contactUsButton,
  ];

  return (
    <div className={styles.contactUs}>
      <div id={'contact-us-title'} className={commonStyles.sectionTitle}>
        {strings.contactUs.title}
      </div>
      <div className={styles.contactRow}>
        <Popover2
          isOpen={nameHelperOpen && getIntent(name) !== Intent.SUCCESS}
          interactionKind='click-target'
          placement='top'
          autoFocus={false}
          className={styles.contactUsInput}
          fill
          content={
            <div className={styles.contactUsHelperText}>
              Please enter your name
            </div>
          }
        >
          <InputGroup
            onFocus={() => setNameHelperOpen(true)}
            onBlur={() => setNameHelperOpen(false)}
            leftIcon={IconNames.PERSON}
            onChange={(event) => setName(event.target.value)}
            value={name}
            placeholder={'Your Name'}
            fill
            large
            maxLength={255}
            intent={getIntent(name)}
          />
        </Popover2>
        <Popover2
          isOpen={emailHelperOpen && getIntent(email, true) !== Intent.SUCCESS}
          interactionKind='click-target'
          placement='top'
          autoFocus={false}
          className={styles.contactUsInput}
          fill
          content={
            <div className={styles.contactUsHelperText}>
              Please enter a valid Email address
            </div>
          }
        >
          <InputGroup
            onFocus={() => setEmailHelperOpen(true)}
            onBlur={() => setEmailHelperOpen(false)}
            className={styles.contactUsInput}
            leftIcon={IconNames.ENVELOPE}
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            placeholder={'Your Email'}
            fill
            large
            maxLength={255}
            intent={getIntent(email, true)}
          />
        </Popover2>
      </div>
      <div className={styles.contactRow}>
        <TextArea
          className={styles.contactUsTextArea}
          fill
          large
          placeholder={'Your Message'}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          intent={getIntent(message)}
          maxLength={10000}
        />
      </div>
      <div className={styles.contactRow}>
        {/* @ts-ignore */}
        <ReCAPTCHA
          className={styles.recaptcha}
          ref={recaptchaRef}
          sitekey='6Ld4grYUAAAAAEBiUXkXVVzFqpVngiDNtkAIS8QN'
          onChange={setCaptcha}
          size={'normal'}
        />
        <div>
          <i style={{ fontSize: '16px', margin: '10px' }}>
            All fields are mandatory
          </i>
          <button
            disabled={!canSubmit}
            className={classnames(submitBtnClasses, 'bp3-dark')}
            onClick={submitContact}
          >
            {isSending ? <Spinner size={30} /> : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
};
