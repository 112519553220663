import React, { useEffect } from "react"
import strings from "../../resources/strings.json";
import classnames from 'classnames';
import commonStyles from "../common.module.scss";
import jungleVillageSchool10 from "../../resources/images/jungleVillageSchool10.jpg";
import jungleVillageSchool2 from "../../resources/images/jungleVillageSchool2.jpg";
import jungleVillageSchool3 from "../../resources/images/jungleVillageSchool3.jpg";
import jungleVillageSchool4 from "../../resources/images/jungleVillageSchool4.jpg";
import jungleVillageSchool11 from "../../resources/images/jungleVillageSchool11.jpg";
import jungleVillageSchool2Thumb from "../../resources/images/jungleVillageSchool2_tn.jpg";
import jungleVillageSchool3Thumb from "../../resources/images/jungleVillageSchool3_tn.jpg";
import jungleVillageSchool4Thumb from "../../resources/images/jungleVillageSchool4_tn.jpg";
import { scrollToTopWithDelay } from "../page-helper";
import { Section } from "../common/section";
import { DonateNow } from "../common/donate-now-section";

type JungleVillageSchoolProps = {
  openDonatePanel: () => void
}

export const JungleVillageSchool = ({ openDonatePanel }: JungleVillageSchoolProps) => {

  useEffect(() => {
    scrollToTopWithDelay();
  });

  return (
    <div>
      <div className={classnames("animate__animated", "animate__fadeIn")}>
        <p className={commonStyles.sectionTitle}>{strings.ourProjects.jungleSchoolTitle}</p>
        <br />
        <p>{strings.ourProjects.jungleVillageSchool1}</p>
      </div>
      <Section img={jungleVillageSchool10} imgPlaceholder={jungleVillageSchool10} paragraphs={[strings.ourProjects.jungleVillageSchool2]} imgWidth={"unset"} imgHeight={"512px"} imgPortrait />
      <Section img={jungleVillageSchool2} imgPlaceholder={jungleVillageSchool2Thumb} paragraphs={[strings.ourProjects.jungleVillageSchool3]} imgHeight={"333px"} imgFirst />
      <Section img={jungleVillageSchool3} imgPlaceholder={jungleVillageSchool3Thumb} paragraphs={[strings.ourProjects.jungleVillageSchool4]} imgHeight={"333px"} />
      <Section img={jungleVillageSchool4} imgPlaceholder={jungleVillageSchool4Thumb} paragraphs={[strings.ourProjects.jungleVillageSchool5]} imgFirst />
      <Section img={jungleVillageSchool11} imgPlaceholder={jungleVillageSchool11} paragraphs={[strings.ourProjects.jungleVillageSchool6]} imgHeight={"unset"} />

      <DonateNow openDonatePanel={openDonatePanel} />
    </div>
  )
}
